<template>
  <a-card>
    <a-tabs v-model="activeKey" @change="handelTabs">
      <a-tab-pane key="1" tab="使用数据"></a-tab-pane>
      <a-tab-pane key="2" tab="转化分析"></a-tab-pane>
    </a-tabs>
    <template-analysis v-if="activeKey == 1"/>
    <analysis-chart v-if="activeKey == 2"/>
  </a-card>
</template>

<script>
import templateAnalysis from "@/pages/app/templateAnalysis/usageData/index";
import analysisChart from "./components/analysis_chart";

export default {
  name: "templateAnalysisList",
  data() {
    return {
      activeKey: "1"
    };
  },
  components: {templateAnalysis, analysisChart},
  mounted() {
  },
  methods: {
    handelTabs() {
    }
  }
};
</script>

<style scoped>

</style>
