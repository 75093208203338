<template>
  <div>
    <a-form-model
        ref="search_form"
        :model="search_form"
        layout="inline"
        class="search_form"
    >
      <a-row>
        <a-col :span="8">
          <a-form-model-item
              label="统计品牌"
          >
            <a-select
                v-model="search_form.principalId"
                show-search
                option-filter-prop="children"
                allow-clear
                placeholder="请输入关键字搜索"
                :filter-option="filterOption"
                style="width: 260px"
            >
              <a-select-option :value="item.id" v-for="item in principal_list" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <a-form-model-item
              label="统计日期"
          >
            <a-range-picker
                :allowClear="false"
                format="YYYY-MM-DD HH:mm:ss"
                value-format="YYYY-MM-DD HH:mm:ss"
                show-time
                inputReadOnly
                @change="onChangeDate"
                :disabled-date="disabledPriceRangeDate"
                @calendarChange="calendarPriceRangeChange"
                style="width:360px"
                v-model="search_form.time"
            />
            <p class="picker-text"><span>*</span>统计范围：下单日期=统计日期的工单</p>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-button
              @click="resetSearch"
              class="mr-10"
          >重置
          </a-button>
          <a-button
              type="primary"
              @click="bindSearch"
          >查询
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <a-row style="padding-top: 20px">
      <a-col :span="11">
        <div class="echarts-top-left">
          <div class="echarts-top">
            <div>
              <p class="echarts-top-title">成片生成数量趋势</p>
              <p class="picker-text" style="line-height: 12px"><span>*</span>成片生成日期 = 统计日期的成片</p>
            </div>
            <a-input placeholder="请输入模板名称查询" style="width: 200px" v-model.trim="search_form.module_name"/>
          </div>
          <BaseCharts
              key="line"
              :style="{width:'95%',height:'400px'}"
              :option="lineData"
          />
        </div>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="11">
        <div class="echarts-top-left">
          <div class="echarts-top">
            <div>
              <p class="echarts-top-title">用户行为转化漏斗</p>
              <p class="picker-text" style="line-height: 12px"><span>*</span>模板创建日期 = 统计日期的模板</p>
            </div>
            <a-input placeholder="请输入模板名称查询" style="width: 200px" v-model.trim="search_form.module_name1"/>
          </div>
          <BaseCharts
              key="funnel"
              :style="{width:'95%',height:'400px'}"
              :option="funnelData"
          />
        </div>
      </a-col>
    </a-row>
    <a-row style="padding-top: 60px">
      <a-col :span="11">
        <div class="echarts-top-left">
          <div class="echarts-top">
            <div>
              <p class="echarts-top-title">分镜数量转化趋势</p>
              <p class="picker-text" style="line-height: 12px"><span>*</span>模板创建日期 = 统计日期的模板，成片生成日期
                = 统计日期的成片</p>
            </div>
          </div>
          <BaseCharts
              class="bar-box"
              ref="BaseCharts"
              key="bar"
              :style="{width:'95%',height:'500px'}"
              :option="barData"
          />
          <div class="echarts-top">
            <div></div>
            <div>
              <p class="picker-text" style="line-height: 12px"><span>*</span>注：折线（即比值）位置越高，对应分镜数的模板，转化为成片的效果越好
              </p>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="11">
        <div class="echarts-top-left">
          <div class="echarts-top">
            <div>
              <p class="echarts-top-title">模板分类转化占比</p>
              <p class="picker-text" style="line-height: 12px"><span>*</span>成片生成日期 = 统计日期的模板</p>
            </div>
          </div>
          <BaseCharts
              class="pie-box"
              key="pie"
              width="55%"
              :option="seriesDate"
              :style="{width:'500px',height:'500px'}"
          />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from "moment";
import { merge } from "lodash";
import BaseCharts from "@/components/BaseChart";
import _api from "@/api/contentProduction.js";
import { Tool } from "@/utils";


export default {
  name: "analysis_chart",
  data() {
    return {
      search_form: {
        module_name: "",
        module_name1: "",
        start_time: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
        time: [moment().startOf("month"), moment().endOf("month")]
      },
      principal_list: [],
      barData: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999"
            }
          }
        },
        legend: {
          data: ["模板", "成片", "比值"]
        },
        xAxis: [],
        yAxis: [
          {
            type: "value",
            name: "",
            min: 0,
            // max: 250,
            // interval: 150,
            axisLabel: {
              formatter: "{value}"
            },
            axisLine: {show: false}, // y轴不显示线
            axisTick: {
              show: false, // 不显示y轴刻度
            },
          },
          {
            type: "value",
            name: "",
            min: 0,
            // max: 25,
            interval: 5,
            axisLabel: {
              formatter: "{value}"
            },
            axisLine: {show: false}, // y轴不显示线
            axisTick: {
              show: false, // 不显示y轴刻度
            },
          }
        ],
        series: []
      },
      seriesDate: {
        grid: {
          top: 20,
          bottom: "20%",
          left: 0,
          right: "80%"
        },
        title: {
          text: "",
          bottom: 0,
          left: "center"
        },
        tooltip: {
          trigger: "item"
        },
        color: ["#598bf8", "#f9c14e", "#58d5a2", "#5b6c8e"], // 自定义饼图颜色
        series: []
      },
      lineData: {
        // legend: {
        //   // show: true,
        //   orient: "horizontal",
        //   itemGap: 20
        // }, // 顶部图列标记
        tooltip: {
          trigger: "axis"
        },
        grid: {
          left: "6%",
          right: "6%",
          bottom: "3%",
          containLabel: true
        },
        color: "#58d5a2", // 自定义颜色
        title: {
          text: "",
          top: 0,
          left: 0
        },
        yAxis: {
          // min: 0,
          // max: (value) => {
          //   return value.max + 200;
          // },
          type: "value",
          show: true,
          axisTick: {
            show: true
          }
        },
        xAxis: [],
        series: []
      },
      funnelData: {
        grid: {
          top: 20,
          bottom: "20%",
          left: 0,
          right: "80%"
        },
        title: {					// 图表标题
          // text: "漏斗图标题",					// 标题文本内容
          target: "blank",							// 链接在新窗口打开
          left: "5%",						// 标题距容器左侧5%
          top: "5%",						// 标题距容器顶部5%
          textStyle: {					// 标题文本样式
            color: "#000",					// 字体颜色
            fontSize: 20,					// 字体大小
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c}" // a对应系列名称,b对应数据项名称,c对应数据项值.
        },
        color: ["#598bf8", "#58d5a2", "#5b6c8e", "#f5b822"], // 自定义饼图颜色
        series: []
      },
      selectPriceDate: "",
      offsetDays: 363, //最多选择范围一年
    };
  },
  components: {BaseCharts},
  mounted() {
    this.getData();
    this.getPrincipalsList();
  },
  methods: {
    getData() {
      this.getProductTrend();
      this.getStoryboardTrend();
      this.getUserAction();
      this.getTagRate();
    },
    getProductTrend() {
      let params = {
        principal_id: this.search_form.principalId,
        module_name: this.search_form.module_name,
        start_time: this.search_form.start_time,
        end_time: this.search_form.end_time,
      };
      for(let key in params) {
        if(params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getProductTrend(params).then(res => {
        if(res.code == 200) {
          const data = res.data || {};
          const linList = [
            {
              name: "成片",
              xAxis: data.xaxis || [],
              type: "line",
              data: data.yaxis || []
            },
          ];
          this.handleLineOptions(linList, "");
        } else {
          this.$message.error(res.message || "获取成片生成数量趋势数据失败");
        }
      });
    }, // 成片生成数量趋势
    getUserAction() {
      let params = {
        principal_id: this.search_form.principalId,
        module_name: this.search_form.module_name1,
        start_time: this.search_form.start_time,
        end_time: this.search_form.end_time,
      };
      for(let key in params) {
        if(params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getUserAction(params).then(res => {
        if(res.code == 200) {
          const data = res.data || {};
          const funnelList = [
            {
              name: "",
              type: "funnel",
              width: "70%",
              height: "80%",
              left: "10%",
              top: "10%",
              sort: "descending",
              maxSize: "80%",
              minSize: "30%",//底部占总长比例
              markLine: {
                symbol: "none",
                lineStyle: {
                  type: "solid",
                  color: "#ccc"
                },
                label: {
                  fontSize: 14,
                  color: "#ccc",
                  animation: false,
                  position: "rightTop",
                },
                tooltip: {
                  show: false			// (提示框不显示)
                },
                data: [
                  [
                    {
                      x: "40%",
                      y: "70%",
                      name: `转化率 ${ Tool.roundToNumber(data.product_to_success_rate * 100) + "%" || "0%" }`,
                    },
                    {
                      x: "70%",
                      y: "70%",
                      name: `转化率 ${ Tool.roundToNumber(data.product_to_success_rate * 100) + "%" || "0%" }`,
                    }
                  ],
                  [
                    {
                      x: "40%",
                      y: "50%",
                      name: `转化率 ${ Tool.roundToNumber(data.shooting_to_product_rate * 100) + "%" || "0%" }`,
                    },
                    {
                      x: "70%",
                      y: "50%",
                      name: `转化率 ${ Tool.roundToNumber(data.shooting_to_product_rate * 100) + "%" || "0%" }`,
                    }
                  ],
                  [
                    {
                      x: "40%",
                      y: "30%",
                      name: `转化率 ${ Tool.roundToNumber(data.view_to_shooting_rate * 100) + "%" || "0%" }`,
                    },
                    {
                      x: "70%",
                      y: "30%",
                      name: `转化率 ${ Tool.roundToNumber(data.view_to_shooting_rate * 100) + "%" || "0%" }`,
                    }
                  ],
                ]
              },
            },
            {
              name: "",
              type: "funnel",
              width: "70%",
              height: "80%",
              left: "10%",
              top: "10%",
              sort: "descending",
              maxSize: "80%",
              minSize: "30%",//底部占总长比例
              label: {
                normal: {
                  position: "inside",     // 标签的位置:'left'漏斗图的左侧）、'right'(右侧)、'inside'(内部) [ default: 'outside' ]
                  formatter: "{b} {c}",      // 标签文本
                  textStyle: {
                    color: "#fff"
                  }
                },
              },
              labelLine: {        // 标签的视觉引导线样式
                normal: {
                  show: true,      // 是否显示引导线
                  length: 50, // 视觉引导线第一段的长度。
                }
              },
              data: [
                {value: data.publish_success_count || 0, name: "发布成功"},
                {value: data.start_shooting_count || 0, name: "拍同款"},
                {value: data.production_count || 0, name: "生成成片"},
                {value: data.view_count || 0, name: "浏览模板"}
              ]
            },
          ];
          this.handleFunnelOptions(funnelList, "");
        } else {
          this.$message.error(res.message || "获取用户行为转化漏斗数据失败");
        }
      });
    }, // 用户行为转化漏斗
    getStoryboardTrend() {
      let params = {
        principal_id: this.search_form.principalId,
        start_time: this.search_form.start_time,
        end_time: this.search_form.end_time,
      };
      for(let key in params) {
        if(params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getStoryboardTrend(params).then(res => {
        if(res.code == 200) {
          const data = res.data || {};
          console.log(data);
          const BarList = [
            {
              name: "模板",
              type: "bar",
              color: "#3b98ff",
              barGap: "0%",
              tooltip: {
                valueFormatter: function(value) {
                  return value;
                }
              },
              label: {
                show: true,
                position: "inside", // 柱子内部：inside，柱子上方：top
                textStyle: {
                  fontSize: "12",
                }
              },
              data: data.module_count_list || []
            },
            {
              name: "成片",
              type: "bar",
              color: "#58d5a2",
              barGap: "0%",
              tooltip: {
                valueFormatter: function(value) {
                  return value;
                }
              },
              label: {
                show: true,
                position: "inside", // 柱子内部：inside，柱子上方：top
                textStyle: {
                  fontSize: "12",
                  color: "#333"
                }
              },
              data: data.product_count_list || []
            },
            {
              name: "比值",
              type: "line",
              yAxisIndex: 1,
              color: "#f4af1f",
              tooltip: {
                valueFormatter: function(value) {
                  return value;
                }
              },
              data: data.rate_list || []
            }
          ];
          this.handleBarOptions(BarList, "", data.level_list || []);
        } else {
          this.$message.error(res.message || "获取分镜数量转化趋势数据失败");
        }
      });
    }, // 分镜数量转化趋势
    getTagRate() {
      let params = {
        principal_id: this.search_form.principalId,
        start_time: this.search_form.start_time,
        end_time: this.search_form.end_time,
      };
      for(let key in params) {
        if(params[key] == "") {
          delete params[key];
        }
      } // 删除空属性
      _api.getTagRate(params).then(res => {
        if(res.code == 200) {
          const data = res.data || [];
          /* const PieList = [
             {value: 20, name: "口播"},
             {value: 30, name: "剧情"},
             {value: 80, name: "卡点"},
             {value: 60, name: "热点"},
           ];*/
          const PieList = data.map(v => {
            return {
              value: v.value,
              name: v.key
            };
          });
          this.handlePieOptions(PieList);
        } else {
          this.$message.error(res.message || "获取分镜数量转化趋势数据失败");
        }
      });
    }, // 模板分类转化占比
    getPrincipalsList() {
      _api.getPrincipalsList().then(res => {
        if(res.code == 200) {
          this.principal_list = res.data || [];
        } else {
          this.principal_list = [];
        }
      });
    }, // 品牌列表
    handleBarOptions(data, title = "", list) {
      this.barData.xAxis = [
        {
          type: "category",
          data: list,
          axisPointer: {
            type: "shadow"
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,//使x轴文字显示全
          },
          axisTick: {
            alignWithLabel: true,// x轴是否与柱子对齐
          }
        }
      ];
      this.barData.series = data;
      /*   this.barData = merge({}, this.barData, {
           xAxis: [
             {
               type: "category",
               data: list,
               axisPointer: {
                 type: "shadow"
               },
               axisLabel: {
                 //x轴文字的配置
                 show: true,
                 interval: 0,//使x轴文字显示全
               },
               axisTick: {
                 alignWithLabel: true,// x轴是否与柱子对齐
               }
             }
           ],
           series: data
         });*/
    },  // 处理柱状图数据
    handlePieOptions(data, title = "") {
      this.seriesDate.series = [
        {
          name: "",
          type: "pie",
          radius: ["0", "50%"],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1
          },
          label: {
            alignTo: "item",
            formatter: "{icon|●} {name|{b}} \n {time|{c}个 {d}%}",
            minMargin: 2,
            edgeDistance: 10,
            lineHeight: 24,
            rich: {
              icon: {
                fontSize: 12,
              },
              name: {
                fontSize: 12,
                fontWeight: "bolder",
              },
              time: {
                fontSize: 12,
                // fontWeight: "bolder",
                color: "#333"
              },
            }
          },
          labelLine: {
            length: 30,
            length2: 8,
          },
          data
        }
      ];
    },  // 处理饼图数据
    handleLineOptions(data, title = "") {
      this.lineData.xAxis = {
        data: data[0].xAxis,
        axisLabel: {
          //x轴文字的配置
          interval: 1000000,
          showMinLabel: true,
          showMaxLabel: true,
        }
      };
      this.lineData.series = data;
    }, // 处理折线图
    handleFunnelOptions(data, title = "") {
      this.funnelData.series = data;
    },  // 处理漏斗图数据
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }, // 品牌过滤
    bindSearch() {
      this.getData();
    },  //搜索
    resetSearch() {
      this.search_form = {
        start_time: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
        time: [moment().startOf("month"), moment().endOf("month")]
      };
      this.getData();
    }, //重置
    onChangeDate(dates) {
      let start_time = "";
      let end_time = "";
      if(dates) {
        start_time = dates[0];
        end_time = dates[1];
      }

      this.search_form.start_time = start_time;
      this.search_form.end_time = end_time;
    },  //日期选择框
    calendarPriceRangeChange(date) {
      this.selectPriceDate = date[0];
    },
    disabledPriceRangeDate(current) {
      const {selectPriceDate, offsetDays} = this;
      if(selectPriceDate) {
        const start = moment(selectPriceDate).subtract(offsetDays, "d").valueOf();
        const end = moment(selectPriceDate).add(offsetDays, "d").valueOf();
        return current < start || current > end;
      } else {
        return false;
      }
    },
  }
};
</script>

<style scoped lang="less">
.picker-text {
  font-size: 12px;
  color: #8b8e92;
  line-height: 16px;

  span {
    font-size: 16px;
    color: red;
    padding-right: 4px;
  }
}

.mr-10 {
  margin-right: 10px;
}


.echarts-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.echarts-top-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 0px;
}
</style>
