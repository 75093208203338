<template>
  <div>
    <search-form
        :search-form="searchForm"
        :principal-list="principalList"
        :temp-cate-list="tempCateList"
        :temp-level-list="tempLevelList"
        :platfrom-list="platfromList"
        @search="handleSearch"
        @reset="handleReset"
    />
    <div class="action-bar">
      <a-button
          type="primary"
          :loading="exporting"
          @click="handleExport"
      >
        导出数据
      </a-button>
    </div>
    <div class="data-table">
      <a-table
          :loading="loading"
          :columns="columns"
          :data-source="data"
          row-key="module_id"
          :pagination="false"
      >
        <div slot="templateInfo" slot-scope="text, record">
          <p>模板名称：{{ record.module_name || "--" }}</p>
          <p>品牌：{{ record.manufactor_name || "--" }}</p>
          <p>车系：{{ record.series_name || "--" }}</p>
          <p>模板分类：{{ record.sort_name || "--" }}</p>
          <p>分镜数量：{{ record.storyboard_count }}</p>
          <p>模板难度：{{ record.form_name || "--" }}</p>
        </div>
        <div slot="platformInfo" slot-scope="text, record">
          <p>统计平台：{{ record.platform_name || "--" }}</p>
          <p>统计时间：{{ record.time || "--" }}</p>
        </div>
        <div slot="usageInfo" slot-scope="text, record">
          <p>浏览模板：{{ record.view_count }}</p>
          <p>拍同款：{{ record.start_shooting_count }}</p>
          <p>生成成片：{{ record.aweme_total }}</p>
          <p>发布视频(失败）：{{ record.publish_fail_total }} <a @click="openPublishModal(record)">[详情]</a></p>
          <p>发布视频(成功）：{{ record.publish_total }}</p>
        </div>
        <div slot="transformEffect" slot-scope="text, record">
          <p>视频播放次数：{{ record.play_volume }}</p>
          <p>视频点赞数：{{ record.digg_count }}</p>
          <p>视频分享数：{{ record.share_count }}</p>
          <p>视频评论数：{{ record.comment_count }}</p>
        </div>
        <div slot="createInfo" slot-scope="text, record">
          <p>创建人：{{ record.creator || "--" }}</p>
          <p>创建时间：{{ record.ctime || "--" }}</p>
        </div>
        <div slot="action" slot-scope="text, record">
          <a-button
              type="link"
              @click="jumpToPage('templateAnalysis:usageDetail', record)"
          >
            详情
          </a-button>
        </div>
      </a-table>
      <base-pagination
          :current-page="pagination.page_num"
          :page-size="pagination.page_size"
          :total="pagination.total"
          @onChange="handlePaginationChange"
          @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <!-- 发布视频（失败）弹框 -->
    <publish-modal
        :visible="visible"
        :loading="loading2"
        :data="data2"
        :pagination="pagination2"
        @cancel="handleCancel"
        @pagination-change="handlePagination2Change"
    />
  </div>
</template>

<script>
import SearchForm from "./components/SearchForm";
import PublishModal from "../components/PublishModal";
import { downloadExcel } from "@/utils";
import templateApi from "@/api/templateAnalysis";

const columns = [
  {
    title: "模板信息",
    key: "templateInfo",
    scopedSlots: {customRender: "templateInfo"}
  },
  {
    title: "统计平台",
    key: "platformInfo",
    scopedSlots: {customRender: "platformInfo"}
  },
  {
    title: "模板使用信息",
    key: "usageInfo",
    scopedSlots: {customRender: "usageInfo"}
  },
  {
    title: "模板转化效果",
    key: "transformEffect",
    scopedSlots: {customRender: "transformEffect"}
  },
  {
    title: "创建信息",
    key: "createInfo",
    scopedSlots: {customRender: "createInfo"}
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: {customRender: "action"}
  }
];

export default {
  name: "usageData",
  components: {
    SearchForm,
    PublishModal
  },
  data() {
    const startTime = this.$moment().subtract(3, "months").format("YYYY-MM-DD");
    const endTime = this.$moment().format("YYYY-MM-DD");

    return {
      searchForm: {
        manufactor_id: "",
        sort: "",
        form: "",
        module_name: "",
        platform: 0,
        time: [startTime, endTime],
        creator: "",
        ctime: []
      },
      loading: false,
      columns,
      data: [],
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      principalList: [
        {label: "全部", value: ""}
      ],
      tempCateList: [
        {label: "全部", value: ""}
      ],
      tempLevelList: [
        {label: "全部", value: ""}
      ],
      platfromList: [
        {label: "全部", value: 0},
        {label: "抖音", value: 1},
        {label: "快手", value: 2},
        {label: "懂车帝", value: 3}
      ],
      exporting: false,
      visible: false,
      loading2: false,
      data2: [],
      pagination2: {
        page_num: 1,
        page_size: 10,
        total: 0
      }
    };
  },
  created() {
    this.fetchUsageDataList();
    this.fetchPrincipalList();
    this.fetchTempCateList();
    this.fetchTempLevelList();
  },
  methods: {
    // 获取使用数据列表
    async fetchUsageDataList() {
      this.loading = true;
      try {
        const {time, ctime, ...restSearchForm} = this.searchForm;
        const {page_num, page_size} = this.pagination;
        const params = {
          ...restSearchForm,
          start_time: time && time.length > 0 ? time[0] : "",
          end_time: time && time.length > 0 ? time[1] : "",
          create_time_start: ctime && ctime.length > 0 ? ctime[0] : "",
          create_time_end: ctime && ctime.length > 0 ? ctime[1] : "",
          page_num,
          page_size
        };
        for(let key in params) {
          if(params[key] == "") {
            delete params[key];
          }
        } // 删除空属性
        const res = await templateApi.fetchUsageDataList(params);

        if(res.code === 200) {
          this.data = res.data.list;
          this.pagination.total = res.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.msg || res.errmsg);
          this.loading = false;
        }
      } catch(e) {
        console.log(e);
        this.loading = false;
      }
    },
    // 处理查询
    handleSearch() {
      this.pagination.page_num = 1;
      this.fetchUsageDataList();
    },
    // 处理重置
    handleReset() {
      this.searchForm = {};
      this.searchForm.time = [this.$moment().subtract(3, "months").format("YYYY-MM-DD"), this.$moment().format("YYYY-MM-DD")];
      this.searchForm.start_time = this.$moment().subtract(3, "months").format("YYYY-MM-DD");
      this.searchForm.end_time = this.$moment().format("YYYY-MM-DD");
      this.pagination.page_num = 1;
      this.fetchUsageDataList();
    },
    // 处理分页
    handlePaginationChange(page_num, page_size) {
      this.pagination.page_num = page_num;
      this.pagination.page_size = page_size;
      this.fetchUsageDataList();
    },
    // 获取主体列表
    async fetchPrincipalList() {
      try {
        const res = await templateApi.fetchPrincipalList();

        this.principalList = [
          ...this.principalList,
          ...res.list.map(item => {
            return {label: item.name, value: item.id};
          })
        ];
      } catch(e) {
        console.log(e);
      }
    },
    // 获取模板分类
    async fetchTempCateList() {
      try {
        const {code, data, message} = await templateApi.fetchTempCateList();

        if(code === 200) {
          this.tempCateList = [
            ...this.tempCateList,
            ...data.map(item => {
              return {label: item.name, value: item.id};
            })
          ];
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.log(e);
      }
    },
    // 获取模板难度列表
    async fetchTempLevelList() {
      try {
        const {code, data, message} = await templateApi.fetchTempLevelList();

        if(code === 200) {
          this.tempLevelList = [
            ...this.tempLevelList,
            ...data.map(item => {
              return {label: item.classify, value: item.id};
            })
          ];
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.log(e);
      }
    },
    // 导出使用数据
    async exportUsageData() {
      this.exporting = true;
      try {
        const {time, ...restSearchForm} = this.searchForm;
        const params = {
          ...restSearchForm,
          start_time: time && time.length > 0 ? time[0] : "",
          end_time: time && time.length > 0 ? time[1] : ""
        };
        const res = await templateApi.exportUsageData(params);

        downloadExcel(res, "模板使用数据");
        this.exporting = false;
      } catch(e) {
        console.log(e);
        this.exporting = false;
      }
    },
    // 处理导出
    handleExport() {
      this.exportUsageData();
    },
    // 获取发布视频失败详情列表(使用数据的)
    async fetchUsageDataPublishFailList() {
      this.loading2 = true;
      try {
        const {module_id} = this.selectedRecord;
        const {platform, time} = this.searchForm;
        const {page_num, page_size} = this.pagination2;
        const params = {
          module_id,
          platform,
          start_time: time && time.length > 0 ? time[0] : "",
          end_time: time && time.length > 0 ? time[1] : "",
          page_num,
          page_size
        };
        const res = await templateApi.fetchUsageDataPublishFailList(params);

        if(res.code === 200) {
          this.data2 = res.data.list.map((item, index) => {
            return {...item, key: index};
          });
          this.pagination2.total = res.data.total;
          this.loading2 = false;
        } else {
          this.$message.error(res.msg || res.errmsg);
          this.loading2 = false;
        }
      } catch(e) {
        console.log(e);
        this.loading2 = false;
      }
    },
    // 打开发布视频(失败）详情
    openPublishModal(record) {
      this.visible = true;
      this.selectedRecord = record;
      this.fetchUsageDataPublishFailList();
    },
    // 处理分页
    handlePagination2Change(page_num, page_size) {
      this.pagination2.page_num = page_num;
      this.pagination2.page_size = page_size;
      this.fetchUsageDataPublishFailList();
    },
    //
    handleCancel() {
      this.visible = false;
      this.data2 = [];
    },
    // 跳转页面
    jumpToPage(name, record) {
      const {module_id} = record;
      const {platform, time} = this.searchForm;

      this.$router.push({
        name,
        query: {
          module_id,
          platform,
          start_time: time[0],
          end_time: time[1]
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.action-bar {
  margin: 10px 0 0;
  text-align: right;
}

.data-table {
  margin: 10px 0 0;
}

.ant-btn-link {
  padding: 0;
}
</style>
