export default {
  data() {
    return {
      $_sidebarElm: null
    }
  },
  mounted() {
    this.$_initResizeEvent()
    this.$_initSidebarResizeEvent()
  },
  activated() {
    this.$_initResizeEvent()
    this.$_initSidebarResizeEvent()
  },
  deactivated() {
    this.$_destroyResizeEvent()
    this.$_destroySidebarResizeEvent()
  },
  beforeDestroy() {
    this.$_destroyResizeEvent()
    this.$_destroySidebarResizeEvent()
  },
  methods: {
    $_initResizeEvent() {
      window.addEventListener("resize", this.$_resizeHandler)
    },
    $_destroyResizeEvent() {
      window.removeEventListener("resize", this.$_resizeHandler)
    },
    $_initSidebarResizeEvent() {
      this.$_sidebarElm = document.getElementsByClassName("ant-layout-sider")[0]
      this.$_sidebarElm && this.$_sidebarElm.addEventListener("transitionend", this.$_sidebarResizeHandler)
    },
    $_destroySidebarResizeEvent() {
      this.$_sidebarElm && this.$_sidebarElm.removeEventListener("transitionend", this.$_sidebarResizeHandler)
    },
    $_sidebarResizeHandler(e) {
      if(e.propertyName === 'width') {
        this.$_resizeHandler()
      }
    }
  }
}
