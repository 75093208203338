<template>
  <a-form-model
    class="search-form"
    ref="searchForm"
    :model="searchForm"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-row :gutter="60">
      <a-col :span="8">
        <a-form-model-item label="品牌" prop="manufactor_id">
          <a-select
            v-model="searchForm.manufactor_id"
            show-search
            :filter-option="filterOption"
            style="width: 100%;"
          >
            <a-select-option
              v-for="item in principalList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="模板分类" prop="sort">
          <a-select
            v-model="searchForm.sort"
            style="width: 100%;"
          >
            <a-select-option
              v-for="item in tempCateList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="模板难度" prop="form">
          <a-select
            v-model="searchForm.form"
            style="width: 100%;"
          >
            <a-select-option
              v-for="item in tempLevelList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="模板名称" prop="module_name">
          <a-input
            v-model="searchForm.module_name"
            allow-clear
            placeholder="请输入"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="统计平台" prop="platform">
          <a-select
            v-model="searchForm.platform"
            style="width: 100%;"
          >
            <a-select-option
              v-for="item in platfromList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="统计时间" prop="time">
          <a-range-picker
            v-model="searchForm.time"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :allow-clear="false"
            :placeholder="['开始日期', '结束日期']"
            style="width: 100%;"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="创建人" prop="creator">
          <a-input
            v-model="searchForm.creator"
            allow-clear
            placeholder="请输入"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="创建时间" prop="date">
          <a-range-picker
              v-model="searchForm.ctime"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :allow-clear="false"
              :placeholder="['开始日期', '结束日期']"
              style="width: 100%;"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item :wrapper-col="{span: 16, offset: 4}">
          <a-space>
            <a-button type="primary" @click="handleSearch">
              查询
            </a-button>
            <a-button @click="handleReset">
              重置
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
export default {
  name: 'SearchForm',
  props: {
    searchForm: {
      type: Object,
      default () {
        return {}
      }
    },
    principalList: {
      type: Array,
      default () {
        return []
      }
    },
    tempCateList: {
      type: Array,
      default () {
        return []
      }
    },
    tempLevelList: {
      type: Array,
      default () {
        return []
      }
    },
    platfromList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    }
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    //
    handleSearch () {
      this.$emit('search')
    },
    //
    handleReset () {
      this.$refs.searchForm.resetFields()
      this.$emit('reset')
    }
  }
}
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
</style>
