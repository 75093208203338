<template>
  <div class="base-chart" ref="baseChart" :style="style"></div>
</template>

<script>
import * as echarts from "echarts";
import resizeMixin from "@/mixins/dataTableMixin";

export default {
  name: "BaseChart",
  mixins: [resizeMixin],
  props: {
    option: {
      type: Object,
      required: true
    },
    width: {
      type: [Number, String],
      default: "100%"
    },
    height: {
      type: [Number, String],
      default: "300px"
    },
    isLimitLegend: {
      type: Boolean,
      default: false
    },
    limitLength: {
      type: Number,
      default: 0
    },
    isClick: {
      type: Boolean,
      default: false
    },
  },
  data() {
    this.$_resizeHandler = () => {
      this.chart && this.chart.resize();
    };

    return {
      chart: null,
      selectedList: [],
    };
  },
  mounted() {
    this.createChart();

    let that = this
    if(this.isLimitLegend) {
      this.chart.on('legendselectchanged', function(params) {
        let option = that.chart.getOption();
        let n = 0;
        let selected = params.selected
        Object.keys(selected).forEach(item => {
          if(selected[item]) {
            n++;
          }
        })

        if(n > that.limitLength) { //不能选择超过指定数
          option.legend[0].selected[that.selectedList[0]] = false
          that.selectedList.splice(0, 1)
          that.selectedList.push(params.name)
          option.legend[0].selected[params.name] = true
        } else {
          if(selected[params.name]) { //选中状态
            that.selectedList.push(params.name)
          } else { //取消状态
            let index = that.selectedList.indexOf(params.name)
            that.selectedList.splice(index, 1)
          }
        }

        that.$emit('getLegend', option.legend[0].selected)
        that.chart.setOption(option)
      });
    }
    if(this.isClick) { //点击图表，获取图表相关数据
      this.chart.getZr().on('click', function (params) {
        var pointInPixel= [params.offsetX, params.offsetY];// 转换像素坐标值到逻辑坐标系上的点
        if (that.chart.containPixel('grid',pointInPixel)) {
          const pointInGrid = that.chart.convertFromPixel('grid', pointInPixel);
          var xIndex = pointInGrid[0];//X轴序号
          console.log({pointInGrid}, pointInPixel)
          var op = that.chart.getOption();//获取当前图表的option
          // var xAxis = op.xAxis[0].data[xIndex];//获得图表中我们想要的数据
          that.$emit('thisIndex', xIndex, op)
        }
      })
    }
  },
  computed: {
    style() {
      const width = typeof this.width === "string" ? this.width : `${ this.width }px`;
      const height = typeof this.height === "string" ? this.height : `${ this.height }px`;

      return `width: ${ width }; height: ${ height };`;
    }
  },
  watch: {
    option: {
      handler(newVal) {
        this.chart && this.chart.setOption(newVal);
        let selected = newVal.legend.selected
        let selectedList = []
        if(selected)  {
          Object.keys(selected).forEach(item => {
            if(selected[item]) {
              selectedList.push(item)
            }
          })
        }
        this.selectedList = selectedList
      },
      deep: true,
      immediate: true
    }
  },
  // watch: {
  //   option (newVal, oldVal) {
  //     this.chart && this.chart.setOption(newVal)
  //   }
  // },
  methods: {
    createChart() {
      this.chart = echarts.init(this.$refs.baseChart);
      this.chart.setOption(this.option);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
