<template>
  <a-modal
    title="发布视频失败详情"
    :visible="visible"
    :footer="null"
    :width="1000"
    @cancel="handleCancel"
  >
    <div class="data-table">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="false"
      >
        <div slot="order" slot-scope="text, record, index">
          {{ index + 1 }}
        </div>
      </a-table>
      <base-pagination
        :currentPage="pagination.page_num"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>
  </a-modal>  
</template>

<script>
const columns = [
  {
    title: '序号',
    key: 'order',
    scopedSlots: { customRender: 'order' }
  },
  {
    title: '帐号',
    dataIndex: 'author_name',
    key: 'author_name'
  },
  {
    title: '经销商',
    dataIndex: 'dealer_name',
    key: 'dealer_name'
  },
  {
    title: '发布平台',
    dataIndex: 'platform_name',
    key: 'platform_name'
  },
  {
    title: '发布时间',
    dataIndex: 'ctime',
    key: 'ctime'
  },
  {
    title: '失败原因',
    dataIndex: 'reason',
    key: 'reason'
  }
]

export default {
  name: 'PublishModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    pagination: {
      type: Object,
      default () {
        return {
          page_num: 1,
          page_size: 10,
          total: 0
        }
      }
    }  
  },
  data () {
    return {
      columns
    }
  },
  methods: {
    //
    handleCancel () {
      this.$emit('cancel')
    },
    // 处理分页改变
    handlePaginationChange (page_num, page_size) {
      this.$emit('pagination-change', page_num, page_size)
    }
  }
}
</script>
